<template>
	<div class="page-title" :style="{ backgroundImage: `url(${getImage()})` }">
		<slot />
	</div>
</template>

<script>
export default {
	props: {
		image: String
	},
	data: () => ({}),
	methods: {
		getImage() {
			return require(`@/assets/images/${this.image}.png`)
		}
	}
}
</script>

<style lang="scss">
.page-title {
	background-repeat: no-repeat;
	background-size: cover;
	background-position-x: left;
	font-size: 4rem;
	line-height: 1.1;
	padding: 2rem;
	color: #fff;
	text-shadow: 0 2px 2px #000;
	margin: 6rem 0;
}
</style>
